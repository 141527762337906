import React from 'react';
import Layout from '../../components/layout';
import HeroCTA from '../../components/sections/heroCTA/heroCTA';
import Segment from '../../components/segment';
import Seo from '../../components/seo';
import ImageText from '../../components/sections/imageText/imageText';
import GetStartedST from '../../components/staticSections/GetStartedST/GetStartedST';
import BgCardsTextST from '../../components/staticSections/bgCardsTextST/BgCardsTextST';
import SocialProofFeedST from '@components/staticSections/socialProofFeedST/socialProofFeedST';
import LicensedPtSliderST from '../../components/staticSections/LicensedPtSliderST/LicensedPtSliderST';
import TestimonialGridST from '../../components/staticSections/TestimonialGridST/TestimonialGridST';
import Table from '../../components/sections/table/table';
import OutcomeST from '../../components/staticSections/OutcomeST/OutcomeST';
import FaqST from '../../components/staticSections/FaqST/FaqST';
import CtaST from '../../components/staticSections/CtaST/CtaST';
import Logo from '@assets/Keymed.jpeg';

const IndexPage = () => {
  return (
    <Layout
      lang="us"
      headerWitdh="header"
      topHeader
      text="In-network with Key Medical Group"
      showFooter
      headerImage={
        <img className="logo--md margin-left--xs flex" alt="Keymedical" src={Logo} />
      }
      keymed
    >
      <Segment id={process.env.SEGMENT_MARKETING_LANDING_PAGES_US} />
      <Seo title="Key Medical Group" language="en" />
      <HeroCTA
        dataTheme="ice-blue"
        imagedata4
        dataSection="hero_CTA"
        alt="Key Medical Group"
        title="Treat joint and back pain from home"
        titleClassName="bold"
        text="Download the Joint Academy app to your phone and get started with a personalized exercise program today."
        textClassName=""
        downloadButton
        downloaddataGoal="Onboarding Viewed"
        downloadhref="https://app.adjust.com/dxewd9i"
        downloadclassname="margin-top--md btn btn--fullwidth btn--lg btn--primary"
        downloadtext="GET STARTED"
        tagHeadline="Get a text to download the app"
        smsTag
        smsTagClassName="display--md"
        dataToken="eyJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MzM1OTE1OTYsImp0aSI6IjE4OTAyOGFkLThlZDQtNGVjNC1hMzNkLWUxNDE3MWYyZTcwZSIsImFmZmlsaWF0ZV9uYW1lIjoiS2V5IE1lZGljYWwgR3JvdXAifQ.4_MySVH-NsIVS95_Vf40olHm3YHsKIkzY_bjzdfPwmk"
      />
      <ImageText
        imgSize="col--lg-3"
        Filename="keymedical.jpg"
        alt="In-network with Key Medical Group"
        Position=""
        title="Partnered with Key Medical Group for Humana Members"
        text="As a patient with Key Medical Group with Humana insurance, Joint Academy is available at no cost to you. Download the Joint Academy app to confirm your coverage or contact support for further assistance."
        download
        downloaddataGoal="Onboarding Viewed"
        downloadhref="https://app.adjust.com/dxewd9i"
        downloadclassname="btn btn--primary btn--md btn--lgMob"
        downloadtext="get started"
      />
      <GetStartedST
        lang="us_en"
        renderDownloadButton
        ctaButtonText="Get Started"
        ctaDataGoal="Onboarding Viewed"
        ctaHref="https://app.adjust.com/dxewd9i"
      />
      <BgCardsTextST dataTheme="light" lang="us_en" />
      <SocialProofFeedST lang="us_en" />
      <TestimonialGridST lang="us_en" />
      <LicensedPtSliderST
        lang="us_en"
        downloaddataGoal="Onboarding Viewed"
        downloadhref="https://app.adjust.com/dxewd9i"
        downloadclassname="btn btn--fullwidth btn--md btn--primary btn--lgMob"
        downloadtext="Get started today"
      />

      <Table
        title="Joint Academy vs. traditional treatment"
        text="Joint Academy is a digital treatment for chronic joint pain that might be beneficial you you compared to traditional face-to-face physical therapy available at clinics."
        images={[
          {
            alt: 'Price table large',
            Filename: 'price-table-large-humana.png',
            className: 'display--sm',
          },
          {
            alt: 'Price table small',
            Filename: 'price-table-small-humana.png',
            className: 'hide--sm',
          },
        ]}
        ctaButtonText="GET STARTED AT NO COST"
        ctaDataGoal="Onboarding Viewed"
        ctaHref="https://app.adjust.com/dxewd9i"
        subText={[
          {
            text: '**Average $30 copay per visit for an average of 4 visits per month.',
            className: '',
          },
        ]}
      />
      <OutcomeST lang="us_en" />
      <FaqST lang="us_en" />
      <CtaST
        lang="us_en"
        ctaDataGoal="Calendly Viewed"
        ctaHref="https://calendly.com/d/k6dx-mdtf/joint-academy-physical-therapy"
        ctaButtonText="Schedule Call"
      />
    </Layout>
  );
};

export default IndexPage;
